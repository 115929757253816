import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Pulse from "react-awesome-reveal";
import "../../styles/insight.css";

export default function BlogView() {
  const location = useLocation();

  useEffect(() => {
    // Additional logic can be added here if needed
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <section className="block relative z-1 bg-white insight-container">
      <div className="insight-content">
        <Pulse>
          <center>
            <img
              alt="Blog Post"
              className="align-middle border-none rounded-lg big-img"
             
              src={location.state.state.image || "https://picsum.photos/seed/picsum/200"}
            />
          </center>
          <br />
          <h5 className="font-semibold text-3xl">
            {location.state.state.title}
          </h5>
          <div
            className="blog-body"
            dangerouslySetInnerHTML={{ __html: location.state.state.body }}
          />
          <br />
          <div className="post-details">
            Posted by <b>{location.state.state.addedby}</b> on <b>{location.state.state.dateadded}.</b>
          </div>
        </Pulse>
      </div>
    </section>
  );
}

import React, { useState, useEffect } from "react";

// components

import BlogsList from "../components/blogs/BlogsList.jsx";
import Navbar from "../components/Navbar/Navbar";
import Footer from "components/Footer/Footer.js";


export default function Blogs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  return (
    
    <>
            <Navbar />
            {/* <Sidebar
                linksData={menuDataHome}
                isMenuOpen={isMenuOpen}
                menuToggle={menuToggle}
            /> */}
            <BlogsList/>
      <Footer/>
    </>
  );
}
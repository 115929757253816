import React, { useState } from "react";
import Slide from "react-awesome-reveal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import h1 from "../../assets/img/h1.png";
import h2 from "../../assets/img/h2.png";
import h3 from "../../assets/img/h3.png";
import "../../styles/Simple.css";
import "../../styles/Home.css"

const Simple = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: !modalIsOpen,
    autoplaySpeed: 5000,
  };

  return (
    <Slide top>
      <div className="simple_container home-carousel">
        <div className="simple_sub_container">
        <div className="simple_container-left">
          {/* Titles for larger screens */}
          <h1 className="simple-main__title desktop-title">
            The <span className="hero-bold textPrimaryColor">simplest</span> way to
          </h1>
          <h1 className="simple-main__title desktop-title">collect cashback</h1>
          <h1 className="simple-main__title desktop-title">automatically</h1>

          {/* Single line title for mobile */}
          <h1 className="simple-main__title mobile-title">
            The <span className="hero-bold textPrimaryColor">simplest</span> way to collect cashback automatically
          </h1>

          <div className="simple-paragraph">
            Paytack is a simple, no-hassle app that allows you to collect
            cashback automatically when you make a purchase with your
            linked bank or card account.
          </div>
        </div>

          <div className="simple_container-right">
            <Slider {...settings}>
              <div className="hero-banner_container-left">
                <img src={h1} alt="" className="phone-img" />
              </div>
              <div className="hero-banner_container-left">
                <img src={h2} alt="" className="phone-img" />
              </div>
              <div className="hero-banner_container-left">
                <img src={h3} alt="" className="phone-img" />
              </div>
            </Slider>
          </div>
        </div>
      </div>
    </Slide>
  )
}

export default Simple;

/*eslint-disable*/
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import Footer from "components/Footers/Footer.js";
import Footer from "components/Footer/Footer.js";
import Navbar from "components/Navbar/Navbar";
import HowItWorks from "components/Home/HowItWorks";
import Simple from "../components/Home/Simple";
import Shopping from "../components/Home/Shopping";
import BlogCarousel from "../components/Home/BlogCarousel";
import HomeRetain from "../components/Home/HomeRetain";
import BlogListhome from "../components/Home/BlogsListHome.js";
// import ScrollTopPage from "../components/ScrollTopPage";
import axios from "axios";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useHistory } from "react-router-dom";


export default function Index() {

  
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const menuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div style={{overflow: "hidden", backgroundColor: "#f8f8f8"}}>
      {/* <ScrollTopPage /> */}
      <Navbar /> 

       <Simple />
      
      <HomeRetain />

      <HowItWorks/> 

      <BlogListhome/>

      <Shopping/> 
      
      <Footer/> 
    </div>
  );
}

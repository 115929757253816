import React from "react";
import Slide from "react-awesome-reveal";
import "../../styles/BusinessBanner.css";

const BusinessBanner = () => {
  return (
    <Slide top>
      <div className="business-banner-container">
        <div className="business-banner-content">
          <div className="business-banner-left">
            <h1 className="business-title">
              All-in-one smart loyalty<br />
              solution to help grow<br />
              your business.
            </h1>
            <p className="business-paragraph">
              Attract more customers, increase sales and build customer
              loyalty. Our platform allows business to build their loyalty 
              programs and get listed on the app with a few clicks.
            </p>
          </div>
          <div className="business-banner-right">
            <img
              src={require("assets/img/businessbanner.png")}
              alt="For Business"
              className="business-image"
            />
          </div>
        </div>
      </div>
    </Slide>
  );
}

export default BusinessBanner;

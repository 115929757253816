import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../assets/img/greenLogo.png";
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa";
import "../../styles/footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column">
          <img src={Logo} alt="PayTack Logo" className="footer-logo" />
          <p className="footer-tagline">Good customer loyalty in every move</p>
          <div className="social-icons">
            <a href="#"><FaFacebookF color="#2cc5b4" /></a>
            <a href="#"><FaLinkedinIn color="#2cc5b4" /></a>
            <a href="#"><FaInstagram color="#2cc5b4" /></a>
          </div>
          <p className="footer-email">info@paytack.com</p>
        </div>
        <div className="footer-column">
          <h3>Quick Links</h3>
          <ul>
            <li><Link to="/">Home</Link></li>
            <li><Link to="/for-business">For Business</Link></li>
            <li><Link to="/how-it-works">How it works</Link></li>
            <li><Link to="/insights">Insights</Link></li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Contact us</h3>
          <p>Sweden</p>
          <p>111 36 Stockholm,</p>
          <p>Drottninggatan 86.</p>
        </div>
      </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} PayTack. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;

import React from "react";

import headIcon from "../../assets/img/home/how-it-works-images/logo_element.svg";
import Bubble from "../../assets/img/home/bubble1.png";
import Icon1 from "../../assets/img/home/retain1.svg";
import Icon2 from "../../assets/img/home/retain2.svg";
import Icon3 from "../../assets/img/home/retain3.svg";
import Icon4 from "../../assets/img/home/retain4.svg";
import { ReactComponent as homeBg1 } from "../../assets/img/home/home-bg1.svg";
import "../../styles/Home.css";
import styled, { keyframes } from "styled-components";
import Pulse from "react-awesome-reveal";
import{ Fade }from "react-awesome-reveal";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const fade = keyframes`
from {
  transform: translateY(50px);
}
to {
  transform: translateY(0px);
}`;

const dash = keyframes`
	from {
		stroke-dashoffset: 100;
	}
	to {
		stroke-dashoffset: 0;
	}
}`;

const BgAnimation = styled(homeBg1)`
  .cricle-bg {
    animation: ${fade} infinite alternate 3s ease;
  }
`;

const HomeRetain = () => {
  return (
    <div className="home-retain" id="business">
      {/* <BgAnimation className="bg-image" /> */}
      <div className="container-body_main" style={{position:'static'}}>
        <section className="container" style={{marginBottom: window.innerWidth < 767 ? '75px' : '0px'}}>
          <Pulse>
            <div className="container-heading">
              <img
                className="container-heading__image"
                src={headIcon}
                alt="bubble"
              />
              <h1 className="container-heading__title">
                Why PayTack?
              </h1>
            </div>
          </Pulse>
          <div className="container-body" style={{marginTop:'50px'}}>
            <Row>
              <Col xs={0} lg={1}></Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row>
                <Fade left>
                  <div className="">
                      <div className="container-body__card-left px-75 text-left">
                        <h4 className="container-body__card-title">
                            Loyalty and Rewards
                        </h4>
                        <p className="homeRetainText">
                          No need for Physical loyalty, anymore. Therefore you will never forget them
                          home or lose them. Now you can have them all in one place. 
                        </p>
                      </div>
                    </div>
                  </Fade>
                </Row>
              </Col>
              <Col xs={12} lg={5}>
                <Row><Fade right>
                    <div className="container-body__card-right text-left  mb-4">
                      <img className="icons-home-retain" src={Icon1} alt="icon1" />
                    </div>
                  </Fade>
                </Row>
              </Col>
            </Row>
            <Row>
            <Col xs={0} lg={1}></Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row>
                <Fade left>
                    <div className="container-body__card-left text-right  px-100  mb-4">
                        <img className="icons-home-retain" src={Icon2} alt="icon1" />
                    </div>
                  </Fade>
                  
                </Row>
              </Col>
              <Col xs={12} lg={5}>
                <Row><Fade right>
                    <div className="container-body__card-right text-left  mb-4">
                      <h4 className="container-body__card-title">
                        The Most Affordable Solution
                      </h4>
                      <p className="homeRetainText">
                        You are rewarded up to 30% cashback for every transaction you make
                        with any paytack partner.
                      </p>
                    </div>
                  </Fade>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={0} lg={1}></Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row>
                <Fade left>
                  <div className="container-body__card-left px-100 text-left">
                      <h4 className="container-body__card-title">
                        Increase Customer Lifetime value
                      </h4>
                      <p className="homeRetainText">
                        You have access to great deals with all paytack partners.<br></br>
                        You can effortlessly find new and exciting coffee shops, restaurants and bars. 
                      </p>
                    </div>
                  </Fade>
                  
                </Row>
              </Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row><Fade right>
                    <div className="container-body__card-right text-left mb-4">
                      <img className="icons-home-retain" src={Icon3} alt="icon1" />
                    </div>
                  </Fade>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col xs={0} lg={1}></Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row>
                <Fade left>
                    <div className="container-body__card-left text-right  px-100  mb-4">
                      <img className="icons-home-retain" src={Icon4} alt="icon1" />
                    </div>
                  </Fade>
                  
                </Row>
              </Col>
              <Col xs={12} lg={5} style={{marginRight: "30px"}}>
                <Row><Fade right>
                    <div className="container-body__card-right text-left mb-4">
                      <h4 className="container-body__card-title">
                        Wider Customer Pool
                      </h4>
                      <p className="homeRetainText">
                        It is a simple and easy tool to get cashback where you already shop.
                        Linking your bank means we can give you automatic cashback every time you make a purchase
                        with a paytack partner. You don't need to do anything else. 
                      </p>
                    </div>
                  </Fade>
                </Row>
              </Col>
            </Row>
            
          
          </div>
        </section>
      </div>
    </div>
  );
};
export default HomeRetain;

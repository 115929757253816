import React, { useState, useEffect } from "react";

// components

import ForBusiness from "../components/ForBusiness/BusinessBanner";
import Navbar from "../components/Navbar/Navbar";
import Footer from "../components/Footer/Footer.js";


export default function ContactUs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  return (
    
    <div style={{overflow: "hidden"}}>
            <Navbar/>
            {/* <Sidebar
                linksData={menuDataHome}
                isMenuOpen={isMenuOpen}
                menuToggle={menuToggle}
            /> */}
            <ForBusiness/>
            <br></br><br></br><br></br>
            <Footer/>
    </div>
  );
}
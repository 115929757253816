import React, { useState, useEffect } from "react";
import axios from "axios";
import Pulse from "react-awesome-reveal";
import { useHistory } from "react-router-dom";
import '../../styles/SearchBar.css';

export default function BlogsList() {
  const navigate = useHistory();
  const [searchItem, setSearchItem] = useState("");
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);

  useEffect(() => {
    getBlogs();
  }, []);

  const handleInputChange = (e) => {
    const searchTerm = e.target.value;
    setSearchItem(searchTerm);

    const filteredItems = blogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredBlogs(searchTerm === "" ? [] : filteredItems);
  };

  const getBlogs = () => {
    axios.get("https://paytack.com/paytackapi/blogs/").then((response) => {
      const publishedBlogs = response.data.filter((blog) => blog.status === 1);
      setBlogs(publishedBlogs);
    });
  };

  const getReadingTime = (content) =>
    Math.ceil(content.replace(/<[^>]*>/g, "").split(/\s+/).length / 200);

  const getExcerpt = (body) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(body, 'text/html');
  
    // Try to select common text elements like <p>, <span>, etc.
    const firstTextElement = htmlDoc.querySelector('p, span, div, h1, h2, h3, h4, h5, h6');
  
    // Get the text content from the selected element or fall back to the entire body
    const content = firstTextElement ? firstTextElement.textContent.trim() : body;
  
    // If content is longer than 100 characters, trim and add ellipsis
    return content.length > 100 ? content.substring(0, 100) + '...' : content;
  };

  const clickBlog = (blog) => {
    navigate.push("blogs/view", {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: blog.addedby,
        status: 1,
      },
    });
  };

  return (
    <>
      <section className="blogs-section">
        <div className="topBlogView text-center">
          <Pulse>
            <img
              alt="Paytack"
              style={{ width: "35px" }}
              src={require("assets/img/pay.png")}
            />
            <h1 className="font-semibold text-3xl">Our Insights</h1>
            <div className="search mb-12">
              <input
                type="text"
                value={searchItem}
                className="searchTerm"
                onChange={handleInputChange}
                placeholder="Type to search"
              />
              <button type="submit" className="searchButton">
                <i className="fa fa-search"></i>
              </button>
            </div>
          </Pulse>
        </div>

        <div className="blogs-grid">
          {(filteredBlogs.length ? filteredBlogs : blogs).map((blog) => (
            <div key={blog.id} className="blog-card">
              <img
                className="blog-image"
                src={blog.image || "https://via.placeholder.com/300"}
                alt={blog.title}
              />
              <div className="blog-content">
                <h3 className="blog-title">{blog.title}</h3>
                <p className="blog-excerpt">{getExcerpt(blog.body)}</p>
                <div className="blog-footer">
                  <span>{getReadingTime(blog.body)} min read</span>
                  <button
                    onClick={() => clickBlog(blog)}
                    className="read-more-btn"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}

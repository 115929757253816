import React, { useState, useEffect } from "react";

// components

import Navbar from "../components/Navbar/Navbar";


export default function ContactUs() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const menuToggle = () => {
        setIsMenuOpen(!isMenuOpen);
    };
  return (
    
    <>
            <Navbar/>
            {/* <Sidebar
                linksData={menuDataHome}
                isMenuOpen={isMenuOpen}
                menuToggle={menuToggle}
            /> */}
    </>
  );
}
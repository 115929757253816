import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "styles/Home.css";

// views without layouts

// import Landing from "views/Landing.js";
// import Profile from "views/Profile.js";
import Index from "views/Index.js";
import About from "views/AboutUs.js";
import Contact from "views/ContactUs.js";
import Blogs from "views/Blogs.js";
import ViewBlog from "views/BlogPreview.js"
import Business from "views/ForBusiness.js";
import HowItWorks from "views/HowItWorks.js";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      {/* add routes without layouts */}
      {/* <Route path="/landing" exact component={Landing}  */}
      <Route path="/business" exact component={Business} />
      <Route path="/about" exact component={About} />
      <Route path="/contact" exact component={Contact} />
      <Route path="/howitworks" exact component={HowItWorks} />
      <Route path="/blogs" exact component={Blogs} />
      <Route path="/blogs/view" component={ViewBlog} />
      {/* <Route path="/terms" exact component={Contact} />
      <Route path="/privacy" exact component={Contact} /> */}
      <Route path="/" exact component={Index} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);

// npm i react-reveal --legacy-peer-deps
// sudo /opt/lampp/manager-linux-x64.run

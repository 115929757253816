import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../../styles/Navbar.css";
import logo from "../../assets/img/newlogo.png";
import mobileLogo from "../../assets/img/mobileLogo.png";
import LogoWithNoWord from "../../assets/img/logo.png";
import greenLogo from "../../assets/img/greenLogo.png";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FaBars, FaTimes } from 'react-icons/fa';
import styled from "styled-components";



const Navbar = () => {

  const [click, setClick] = useState(false);
  const [color, setColor] = useState(false)

  const LogoDiv = styled.div`
  width: 135px;
  height: 40px;

  div {
    background-image: url(${(color ? `${logo}` : `${greenLogo}`)});
    height: 40px !important;
    background-size: contain;
    background-repeat: no-repeat;
  }

  img {
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    img {
      width: 85%;
      -webkit-filter: brightness(0) invert(1);
      filter: brightness(0) invert(1);
    }
    .logo_img {
      background-image: url(${LogoWithNoWord}) !important;
      height: 40px !important;
      background-size: contain;
      background-repeat: no-repeat;
      margin-left: 15px;
    }
  }
`;

    const changeColor =() =>{
      if(window.scrollY >=80){
        setColor(true);
        console.log("Scrolled");
      } else{
        setColor(false);
        console.log("Not scrolled");
      }
    }
    window.addEventListener("scroll", changeColor);
    const handleClick = () => setClick(!click);
    const handleIconClick = () => {
      setClick(!click);
      setColor(true);
    }
    const closeMenu = () => setClick(false);

    return (
        <div className={color? 'header header-bg': 'header'}>
            <nav className='navbar'>
                <a href='/' className='logo'>
                    {/* <img src={greenLogo} alt='logo' /> */}
                    <LogoDiv>
                      <div className=""></div>
                    </LogoDiv>
                    
                </a>
                <div className='hamburger' onClick={handleIconClick}>
                    {click ? (<FaTimes size={30} style={{ color: '#ffffff' }} />)
                        : (<FaBars size={30} style={{ color: color? '#ffffff': '#43cdbc'  }} />)}

                </div>
                <ul className={click ? "nav-menu active" : "nav-menu"}>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/"
                      activeClassName={color? 'active scrolled-link': 'active prescrolled-link'}
                      className={color? 'nav-links scrolled-link': 'nav-links prescrolled-link'}
                      onClick={handleClick}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/business"
                      activeClassName={color? 'active scrolled-link': 'active prescrolled-link'}
                      className={color? 'nav-links scrolled-link': 'nav-links prescrolled-link'}
                      onClick={handleClick}
                    >
                      For Business
                    </NavLink>
                  </li>
                  {/* <li
                    onClick = {(e)=>{
                    e.preventDefault();
                    window.scrollTo({
                    top: document.querySelector("#my-target").offsetTop,
                    behavior: "smooth",
                  });}}>
                     How it works
                  </li> */}
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/howitworks"
                      activeClassName={color? 'active scrolled-link': 'active prescrolled-link'}
                      className={color? 'nav-links scrolled-link': 'nav-links prescrolled-link'}
                      onClick={handleClick}
                    >
                      How it works
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      exact
                      to="/blogs"
                      activeClassName={color? 'active scrolled-link': 'active prescrolled-link'}
                      className={color? 'nav-links scrolled-link': 'nav-links prescrolled-link'}
                      onClick={handleClick}
                    >
                      Insights
                    </NavLink>
                  </li>
                </ul>
            </nav>
        </div>
    )
}

export default Navbar;
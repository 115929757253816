import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "../../styles/newBlog.css";

const BlogCarousel = () => {
  const [blogs, setBlogs] = useState([]);
  const [currentSlide, setCurrentSlide] = useState(0);
  const navigate = useHistory();
  const sliderRef = React.useRef(null);

  useEffect(() => {
    axios.get('https://paytack.com/paytackapi/blogs/')
      .then(response => setBlogs(response.data.filter(blog => blog.status === 1)));
  }, []);

  const getReadingTime = (content) => Math.ceil(content.replace(/<[^>]*>/g, '').split(/\s+/).length / 200);

  const getExcerpt = (body) => {
    const parser = new DOMParser();
    const htmlDoc = parser.parseFromString(body, 'text/html');
  
    // Try to select common text elements like <p>, <span>, etc.
    const firstTextElement = htmlDoc.querySelector('p, span, div, h1, h2, h3, h4, h5, h6');
  
    // Get the text content from the selected element or fall back to the entire body
    const content = firstTextElement ? firstTextElement.textContent.trim() : body;
  
    // If content is longer than 100 characters, trim and add ellipsis
    return content.length > 100 ? content.substring(0, 100) + '...' : content;
  };
  
  const clickBlog = async (blog) => {
    console.log("blog", blog);
  
    navigate.push('blogs/view', {
      state: {
        id: blog.id,
        title: blog.title,
        body: blog.body,
        image: blog.image,
        dateadded: blog.dateadded,
        addedby: blog.addedby,
        status: blog.status,
      }
    });
  };
  

   // Determine the number of slides to show based on the number of blogs
   const slidesToShow = blogs.length < 3 ? blogs.length : 3;

   const settings = {
     dots: true,
     infinite: true,
     speed: 500,
     slidesToShow: slidesToShow,
     slidesToScroll: 1,
     beforeChange: (oldIndex, newIndex) => setCurrentSlide(newIndex), // Track the current slide
     customPaging: (i) => (
       <div className={i === currentSlide ? 'dot active-dot' : 'dot'}></div> // Show active/inactive dots
     ),
     responsive: [
       {
         breakpoint: 1024,
         settings: {
           slidesToShow: slidesToShow,
         }
       },
       {
         breakpoint: 768,
         settings: {
           slidesToShow: blogs.length < 2 ? blogs.length : 2, // Show 1 or 2 for smaller screens
         }
       },
       {
         breakpoint: 640,
         settings: {
           slidesToShow: 1,
         }
       }
     ]
   };

  return (
    <div className="blog-carousel-wrapper">
      <h2 className="text-3xl font-bold text-center ">Latest Insights</h2>
      <div className="blog-carousel">
        <button className="nav-button prev" onClick={() => sliderRef.current.slickPrev()}>
          <FaChevronLeft />
        </button>
        <Slider ref={sliderRef} {...settings}>
          {blogs.map((blog) => (
            <div key={blog.id} className="blog-container">
              <div className="blog-inner" onClick={() => clickBlog(blog)}>
                <img
                  className="blog-image"
                  src={blog.image || "https://via.placeholder.com/300"}
                  alt={blog.title}
                />
                <div className="blog-content">
                  <h3 className="blog-title">{blog.title}</h3>
                  <p className="blog-excerpt">{getExcerpt(blog.body)}</p>
                </div>
                <div className="blog-footer">
                  <span className="blog-reading-time">{getReadingTime(blog.body)} min read</span>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click event from bubbling up
                      clickBlog(blog);
                    }}
                    className="read-more-btn"
                  >
                    Read more
                  </button>
                </div>
              </div>
            </div>
          ))}
        </Slider>
        <button className="nav-button next" onClick={() => sliderRef.current.slickNext()}>
          <FaChevronRight />
        </button>
      </div>
    </div>
  );
};

export default BlogCarousel;

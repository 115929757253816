import React from "react";

import Pulse from "react-awesome-reveal";
import Bounce from "react-awesome-reveal";
import Slide from "react-awesome-reveal";
import Zoom from 'react-awesome-reveal';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { useMediaQuery } from "react-responsive";

import AppStore from "../../assets/img/footer-images/appstore.svg";
import PlayStore from "../../assets/img/footer-images/googleplay.svg";
import "../../styles/Shopping.css";

const Shopping = () => {
  const isMobile = useMediaQuery({ maxWidth: 768 });

  return (
    <div className="shopping-container pt-25" id="app">
    <Pulse>
      <h1>Taking shopping to the next level</h1>
    </Pulse>
    <Pulse>
      <p>
        Download the free Paytack cash back app now to start earning cashback in
        your favorite locals
      </p>
    </Pulse>
    {/* <Zoom>
      <div className={`app-stores ${isMobile ? 'mobile' : ''}`}>
        <Slide left>
          <a href="/play" className="store-button">
            <img src={PlayStore} alt="play_store" />
            <div className="store-text">
              <span>GET IT ON</span>
              <span>Google Play</span>
            </div>
          </a>
        </Slide>
        <Slide right>
          <a href="/play" className="store-button">
            <img src={AppStore} alt="app_store" />
            <div className="store-text">
              <span>Download on the</span>
              <span>App Store</span>
            </div>
          </a>
        </Slide>
      </div>
    </Zoom> */}
  </div>
  );
};

export default Shopping;